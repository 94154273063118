import * as React from "react"

import { Link } from "gatsby"

import { useTranslation } from "react-i18next"
import "../../i18n.js"

import Layout from "../components/layout"
import Seo from "../components/seo"
import BlogBanner from "../components/blog-banner/blogBanner"

import resourcesBanner from '../images/about-banner.gif';

const RightArrow = () => (
  <svg className="inline-block w-8 h-6 md:w-12 md:h-10" viewBox="0 0 47 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.4091 41.0002L22.9091 37.5457L37.3182 23.1366H0V18.1366H37.3182L22.9091 3.77295L26.4091 0.272949L46.7727 20.6366L26.4091 41.0002Z" fill="white" />
  </svg>
)

const Resources = () => {

  const { t } = useTranslation()

  return (
    <Layout navbarTheme="light" className="bg-blue">
      <Seo title={t("metaResourcesTitle")} description={t("metaResourcesDescription")} canonical="https://tio.ist/resources/" />
      <div className="mt-28 lg:mt-32">
        <div className="mb-16 lg:mb-24">
          <h1 className="px-5 md:px-16 text-[2.875rem] md:text-8xl lg:text-9xl font-extrabold text-white whitespace-pre-line w-auto">
            {t("resourcesTitle")}
          </h1>
          {/*  <div className="ticker-wrap-v2 bg-transparent text-white text-6xl md:text-8xl lg:text-9xl font-light italic">
            <div className="ticker z-10">
              <div className="ticker-item w-auto mx-20">
                <p>{t("resourcesTickerTitle")}</p>
              </div>
              <div className="ticker-item w-auto mx-20">
                <p>{t("resourcesTickerTitle")}</p>
              </div>
              <div className="ticker-item w-auto mx-20">
                <p>{t("resourcesTickerTitle")}</p>
              </div>
              <div className="ticker-item w-auto mx-20">
                <p>{t("resourcesTickerTitle")}</p>
              </div>
              <div className="ticker-item w-auto mx-20">
                <p>{t("resourcesTickerTitle")}</p>
              </div>
            </div>
            <div className="ticker z-10">
              <div className="ticker-item w-auto mx-20">
                <p>{t("resourcesTickerTitle")}</p>
              </div>
              <div className="ticker-item w-auto mx-20">
                <p>{t("resourcesTickerTitle")}</p>
              </div>
              <div className="ticker-item w-auto mx-20">
                <p>{t("resourcesTickerTitle")}</p>
              </div>
              <div className="ticker-item w-auto mx-20">
                <p>{t("resourcesTickerTitle")}</p>
              </div>
              <div className="ticker-item w-auto mx-20">
                <p>{t("resourcesTickerTitle")}</p>
              </div>
            </div>
          </div> */}

          <div className="flex overflow-hidden text-white !leading-tight text-6xl md:text-8xl lg:text-9xl font-light italic">
            <div className="flex items-center flex-none min-w-[150%] animate-scroll-left">
              <p>{t("resourcesTickerTitle")}</p>
            </div>
            <div className="flex items-center flex-none min-w-[150%] animate-scroll-left">
              <p>{t("resourcesTickerTitle")}</p>
            </div>
          </div>

        </div>

        <div className="flex justify-end pr-8 md:pr-20 lg:pr-96">
          <p className="text-cream text-xl lg:text-3xl max-w-[10rem] md:max-w-md lg:max-w-lg">{t("resourcesDescription")}</p>
        </div>

        <div className="mx-5 md:mx-16 space-y-16 lg:space-y-32 py-28 md:mt-4 mb-6 md:mb-28 lg:mb-36">
          <h2>
            <a href="https://www.figma.com/community/file/981162887439865059" target="_blank" rel="noreferrer" className="group font-bold text-white text-4xl md:text-6xl">
              <span className="border-b-2 border-transparent group-hover:border-white">{t("figmaSmTemplate")}</span>{" "}
              <RightArrow />{" "}<RightArrow />{" "}<RightArrow />{" "}<RightArrow />{" "}<RightArrow />
            </a>
          </h2>
          <h2>
            <a href="https://www.figma.com/community/file/1161306617324495511" target="_blank" rel="noreferrer" className="group font-bold text-white text-4xl md:text-6xl">
              <span className="border-b-2 border-transparent group-hover:border-white">{t("empathyMap")}</span>{" "}
              <RightArrow />
            </a>
          </h2>
        </div>

        <div className="mx-5 md:mx-16 mt-16 md:mt-32 mb-10 lg:mb-20">
          <div className="w-full">
            <img src={resourcesBanner} alt="tio" className="w-full" />
          </div>
        </div>

        <div className="mx-5 md:mx-16 mb-64">
          <p className="text-white font-bold text-6xl md:text-8xl lg:text-9xl">
            {t("whatDoYouNeed")}{" "}
            <Link to="/contact" className="group italic font-light">
              <span className="border-b-2 border-transparent group-hover:border-white">{t("tellUs")}</span>{" "}
              <svg className="inline-block align-top pt-2 md:pt-3 w-10 h-10 md:w-16 md:h-16 lg:w-20 lg:h-20 fill-current text-white" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.95455 34.8636L0 31.3182L30.7273 4.90909H10.3636L11.2273 0H40L35.2273 28.8182H30.2727L33.6818 8.45454L2.95455 34.8636Z" />
              </svg>
            </Link>
          </p>
        </div>
      </div>
      <BlogBanner />
    </Layout>
  )
}

export default Resources